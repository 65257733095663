import { FormControl, FormGroup } from '@angular/forms';
import { PrismicService } from 'src/app/_services/prismic.service';

import { AfterViewInit, Component, DoCheck, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CollapseComponent, MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { combineLatest, distinctUntilChanged, filter, map, Observable, Subject, Subscription, take, takeUntil, timeout } from "rxjs";
import { AppConfigService } from "src/app/appconfig/appconfig.service";
import { ModalInfoComponent } from "src/app/modal-info/modal-info.component";
import { RatesResponse } from "src/app/_models/rates";
import { Vehicle } from "src/app/_models/vehicle";
import { ProductTabsetService } from "src/app/_services/product-tabset.service";
import brandInfo from "src/environments/brand.json";
import moment from 'moment'
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { SelectedPlanService } from 'src/app/_services/selectedPlan.service';
import * as SelectedPlanActions from "../../states/planSelect/selectedPlan.actions";
import { ContractService } from 'src/app/_services/contract.service';


@Component({
  selector: 'app-coverage-options',
  templateUrl: './coverage-options.component.html',
  styleUrls: ['./coverage-options.component.scss']
})
export class CoverageOptionsComponent implements OnInit, AfterViewInit {
  @Input() plans: any;
  @Input() chosenPlan: any;
  @Input() title: any;
  @Input() subTitle: any;
  @Input() _deductible: string;
  @Input() select_coverage_term: string;
  @Input() select_claim_deductible: string;
  @Input() estimated_coverage_through: string;

  @Input() changeTerms: boolean;
  @Input() index: number
  @Input() _commercial_use_toggle: boolean = false
  @Input() _snow_plow_toggle: boolean  = false
  @Input() _grey_market_toggle: boolean  = false
  @Input() _lift_kit_toggle: boolean  = false
  @Output() chosenTerm = new EventEmitter<any>();
  @Output() emitChangeTerms = new EventEmitter<any>();
  @Output() emitAddToCart = new EventEmitter<string>();

  termMiles: any;
  termYears: any;
  termsAndRates: any = [];
  initialized: boolean;
  currentPlanIndex: number;
  check: any;
  modalRef: MDBModalRef;
  commercial_use_tooltip = "";
  term_length_tooltip = "";
  snow_plow_tooltip = "";
  lift_kit_tooltip = "";
  appConfigSubscription: Subscription;
  mileage: string;
  showLiftKit = false;
  showSnowPlow = false;
  grey_market_tooltip: string;
  expireMileage: number;
  expireDate: any;
  preferredLanguage: string;
  isBrand: string;

  //Select Claim deductible
  @ViewChildren('currentDeductiblePanel') currentDeductiblePanel: QueryList<CollapseComponent>;
  @Input() planSelected: any;
  @Input() allPlans: any;

  deductibles: any[];
  currentDeductible: number;
  multipleDeductibles = brandInfo.multiDeductible;
  disappearingDeductible = brandInfo.disappearingDeductible;
  disappearingDeductiblePanel = false;
  disappearingDeductibleValue = false;
  canSelect = true;
  planSelected$: Observable<any>;
  private destroy$ = new Subject<void>();
  allPlans$: Observable<any>;
  combinedPlans$: Observable<any>;
  selectedTermsVal = 0;
  selectedDeductible = 0;
  coverageTermsForm: FormGroup<{ terms: FormControl<number>; deductible: FormControl<number>; }>;
  coverageDates: { coverageStartDate: any; coverageEndDate: any; };
  // coverageTermsForm = new UntypedFormGroup({
  //   terms: new UntypedFormControl(2, Validators.required),
  //   deductible: new UntypedFormControl(Validators.required),
  // });



  //END Select Claim deductible

   constructor(
    public store: Store<{selectedPlan: any}>,
    private prismicService: PrismicService,
    public router: Router, private modalService: MDBModalService,
    private appConfig: AppConfigService,
    private translate: TranslateService,
    private productTabSetService: ProductTabsetService,
    private selectedPlanService: SelectedPlanService,
    private contractService: ContractService,
    ) {
     }
  ngAfterViewInit(): void {
  }


  ngOnInit(): void {
    console.log('this.chosenPlan.planTerms', this.chosenPlan ? this.chosenPlan.planTerms : null)
    console.log("coverage-select ngOnInit")
    console.log('PLANNNNNNS plan', this.plans);
    this.isBrand = brandInfo.brand

    this.coverageDates = this.contractService.getCoverageStartDate();

    this.coverageTermsForm = new FormGroup({
      terms: new FormControl(0, [Validators.required]),
      deductible: new FormControl(0, [Validators.required]),
    });

    this.store.select((store) => store.selectedPlan).pipe(
      filter(val => val),
      takeUntil(this.destroy$),
      ).subscribe((val) => {
        if(val.currentSelectedPlan){
          let termsData = val.currentSelectedPlan.planTerms[val.currentSelectedPlan.term.termIndex].label;
          this.termMiles = termsData.split(' ')[2];
          this.termYears = termsData.split(' ')[0];
          this.setStateCoverageEstimate();
        }
      })

    this.store.select((store) => store.selectedPlan.defaultPlan).pipe(
      filter(val => val),
      takeUntil(this.destroy$),
      ).subscribe((val) => {
        const oldPlan = this.planSelected;
        const selectedCoverageValue = this.coverageTermsForm.value.terms;
        const selectedCoverageTermLabel = oldPlan?.planTerms.find(v => v.value === +selectedCoverageValue);
        this.store.dispatch(SelectedPlanActions.loadCurrentSelectedPlan({ currentSelectedPlan: val }));
        this.planSelected = val;

        if (selectedCoverageTermLabel) {
          const newSelectedValue = this.planSelected?.planTerms.find(v => v.label === selectedCoverageTermLabel.label);
          // setTimeout(() => this.coverageTermsForm.patchValue({terms: newSelectedValue.value}));
          setTimeout(() => {
            this.coverageTermsForm.patchValue({terms: newSelectedValue.value});
          })
        }

        this.updatePlans();
    });

    this.coverageTermsForm.get('deductible').valueChanges.subscribe(value => {
      this.selectedDeductible = value;
      this.updatePlans();
    });
    this.coverageTermsForm.get('terms').valueChanges.subscribe(value => {
      this.selectedTermsVal = value;
      this.updatePlans();
    });
    if (brandInfo.multiDeductible) {
      this.setDeductible();
    }

    // this.uniquePlanTerms = this.selectedPlanService.extractUniquePlanTerms(this.plans);





    // Get Commercial Tooltip from Prismic
    this.appConfigSubscription = this.appConfig.getSettings().subscribe((result) => {
      this.commercial_use_tooltip = this.appConfig.getCommercialUseTooltip();
      this.term_length_tooltip = this.appConfig.getTermLengthTooltip();
      this.snow_plow_tooltip = this.appConfig.getSnowPlowTooltip();
      this.lift_kit_tooltip = this.appConfig.getLiftKitTooltip();
      this.grey_market_tooltip = this.appConfig.getGreyMarketTooltip()
    });

    // Set translation
    // this.translate.get("GENERIC.MILEAGE_PLURAL").subscribe((mileage) => {
    //   this.mileage = mileage.toLowerCase();
    // });

    // TODO set these to pull from API
    if (["Chevrolet", "GMC"].includes(brandInfo.brand)) {
      this.showLiftKit = true;
      this.showSnowPlow = true;
    }

    if (brandInfo.coverageBasedOnISD) {
      const rates: RatesResponse = JSON.parse(sessionStorage.getItem('rates'))

      if (rates.data.productDetailsList[0].productCode === 'NASP') {
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
        if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
          //this.setStateCoverageEstimate();
        }
      }

      if ((brandInfo.brand === 'BMW' || brandInfo.brand === 'MINI') && rates.data.productDetailsList[0].productPlanList[0].planName.toLocaleLowerCase().includes('in warranty')) {
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
        if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
          //this.setStateCoverageEstimate();
        }
      }
    }

    this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en-ca');
    registerLocaleData(localeEn, 'en-us');



  }

  private updatePlans(): void {
    const termIndex = this.planSelected?.planTerms.findIndex(v => {
      return +v.value === +this.selectedTermsVal;
    });
    if (brandInfo.multiDeductible) {
      this.selectedPlanService.updatePlansWithMultiDeductible(this.plans, termIndex, this.planSelected, brandInfo, this.selectedDeductible, this.disappearingDeductibleValue);
      this.selectedPlanService.storingSelectedPlan(termIndex, this.planSelected, brandInfo, this.selectedDeductible, this.disappearingDeductibleValue)

    } else {
      const singleDeductible = this.planSelected.plan.deductible
      this.selectedPlanService.updatePlansWithSingleDeductible(this.plans, termIndex, this.planSelected, brandInfo, singleDeductible);
      this.selectedPlanService.storingSelectedPlan(termIndex, this.planSelected, brandInfo, singleDeductible, this.disappearingDeductibleValue)

    }

  }



  setStateCoverageEstimate() {
    let _vehicle: any = JSON.parse(sessionStorage.getItem("vehicle"));
    if (_vehicle.inserviceDate && (brandInfo.brand === 'NCESI' || (brandInfo.brand  === 'BMW' || brandInfo.brand === 'MINI') && this.chosenPlan.plan.planNumber.includes('IW'))) {
        this.expireDate = new Date(_vehicle.inserviceDate.replace(/-/g, '/'));
        this.expireDate = this.expireDate.setFullYear(this.expireDate.getFullYear() + parseInt(this.termYears));
        this.expireDate = moment(this.expireDate).format('MMMM D, yyyy');
        this.expireMileage = Number(this.termMiles.toString().replace(/,/g, ''))
    } else if(brandInfo.brand === 'Lambo') {
      const contractDates = this.contractService.getCoverageStartDate();
      console.log('contractDatescontractDates', contractDates)

    }

    else {
      this.expireDate = new Date();
      this.expireDate = this.expireDate.setFullYear(this.expireDate.getFullYear() + parseInt(this.termYears));
      this.expireDate = moment(this.expireDate).format('MMMM D, yyyy');
      this.expireMileage =  Number(_vehicle.mileage.toString().replace(/,/g, '')) + Number(this.termMiles.toString().replace(/,/g, ''));
    }

  }
  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }


//   resetPricing() {
//     console.log("coverage-select resetPricing")

//     let termsData = this.chosenPlan.planTerms;
//     this.chosenPlan.term.termIndex = 0;
//     this.chosenPlan.term.numberOfPayments = Number(termsData[this.chosenPlan.term.termIndex].paymentTerms[0].split(" ")[0]);
//     this.chosenPlan.term.price = (Number(termsData[0].planTermSKURate) / 10).toFixed(2).toString();
//     this.chosenPlan.term.totalPrice = termsData[0].planTermSKURate;
//     if (brandInfo.frontLoadTaxes) {
//       this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//     } else {
//       this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//     }

//     this.chosenPlan.plan.commercialUse = false;

//     // if (this.coverageTermsForm) {
//     //   this.coverageTermsForm.patchValue({ commercialUse: false });
//     // }

//     this.handleCommercialUse(null);

//     this.chosenTerm.emit(this.chosenPlan);
//   }


//   handleSnowPlow(snowPlow) {
//     // TODO
//     console.log("coverage-select  handleSnowPlow")


//     let termsData = this.chosenPlan.planTerms;
//     console.log("termsData", this.chosenPlan, termsData);

//     console.log("Handle Snow Plow", snowPlow);


//     const price = this.productTabSetService.getPriceForSurcharge('Plow')

//     if (snowPlow) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.snowPlowSelected = snowPlow;
//       this.chosenTerm.emit(this.chosenPlan);

//     } else if (!snowPlow && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.snowPlowSelected = snowPlow;
//       this.chosenTerm.emit(this.chosenPlan);

//     }
//   }

//   handleLiftKit(liftKit) {
//     console.log("coverage-select  handleLiftKit")


//     let termsData = this.chosenPlan.planTerms;
//     console.log("termsData", this.chosenPlan, termsData);

//     console.log("Handle Lift Kit", liftKit);
//     let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));

//     const price = this.productTabSetService.getPriceForSurcharge('Lift Kit')

//     console.log(price)

//     if (liftKit) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.liftKitSelected = liftKit;
//       this.chosenTerm.emit(this.chosenPlan);

//     } else if (!liftKit && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price;
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.liftKitSelected = liftKit;
//       this.chosenTerm.emit(this.chosenPlan);

//     }
//   }

//   handleGreyMarket(greyMarket) {
//     console.log("coverage-select  handleGreyMarket")


//     let termsData = this.chosenPlan.planTerms;
//     console.log("termsData", this.chosenPlan, termsData);

//     console.log("Handle Grey Market", greyMarket);
//     let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));

//     const price = this.productTabSetService.getPriceForSurcharge('Grey Market')

//     if (greyMarket) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.greyMarketSelected = greyMarket;
//       this.chosenTerm.emit(this.chosenPlan);

//     } else if (!greyMarket && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price;
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.greyMarketSelected = greyMarket;
//       this.chosenTerm.emit(this.chosenPlan);

//     }
//   }

//   handleCommercialUse(commercialUse) {
//     console.log("coverage-select  handleCommercialUse")


//     let termsData = this.chosenPlan.planTerms;
//     console.log("termsData", this.chosenPlan, termsData);

//     console.log("Handle Commercial Use", commercialUse);
//     let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));

//     const price = this.productTabSetService.getPriceForSurcharge(['GMC', 'Cadillac', 'Chevrolet', 'Buick'].includes(brandInfo.brand) ? 'Professional Services' : 'Light Duty')

//     console.log(this.coverageTermsForm.controls["commercialUse"].value)

//     if (commercialUse) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.commercialUseSelected = commercialUse;
//       this.chosenTerm.emit(this.chosenPlan);

//     } else if (!commercialUse && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

//       this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price;
//       this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
//       if (brandInfo.frontLoadTaxes) {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       } else {
//         this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
//       }

//       this.chosenPlan.term.commercialUseSelected = commercialUse;
//       this.chosenTerm.emit(this.chosenPlan);

//     }
//   }

//   convertTermLabel(term:string) {
//     term = term.toUpperCase();
//     var years1 = (term.includes(' YEARS') ? term.split(' YEARS') : term.includes(' YEAR(S)') ? term.split(' YEAR(S)') : term.split(' YEAR'));
//     var years2 = (term.includes(' YEARS, ') ? term.split(' YEARS, ') : term.includes(' YEAR(S), ') ? term.split(' YEAR(S), ') : term.split(' YEAR, '));
//     return (years1[0]) + ' ' + this.translate.instant("GENERIC.YEARS") + ' ' + this.translate.instant("RATES_PAGE.OR") + ' ' + years2[1].split(' ')[0] + ' ' + this.translate.instant("GENERIC.MILEAGE_PLURAL").toLowerCase()
//   }

//   showInfoModal(content: String) {
//     const modalOptions = {
//       class: "modal-md",
//       ignoreBackdropClick: true,
//       containerClass: "",
//       animated: true,
//       data: {
//         title: "What is Professional Services Coverage?",
//         content:
//           "Professional Services means a company or pool vehicle used for the following commercial purposes: business travel, site inspection, light delivery, service or repair, and snow removal, provided that the Covered Vehicle is equipped for snow removal, as recommended by the manufacturer. Professional Services is considered to be a Prohibited Commercial Purpose, unless the Optional Professional Services box was selected on the Registration Page.",
//       },
//     };

//     this.modalRef = this.modalService.show(ModalInfoComponent, modalOptions);
//   }

//   addToCart() {
//     console.log("coverage-select addToCart")

//     this.emitAddToCart.emit("addToCart");
//   }

//   getFormattedDate() {
//     let d = new Date();
//     let year = d.getFullYear().toString();
//     let day = d.getDate().toString();
//     let month = d.getMonth().toString();

//     if (month === "0") {
//       month = "01";
//     } else if (month === "1") {
//       month = "02";
//     } else if (month === "2") {
//       month = "03";
//     } else if (month === "3") {
//       month = "04";
//     } else if (month === "4") {
//       month = "05";
//     } else if (month === "5") {
//       month = "06";
//     } else if (month === "6") {
//       month = "07";
//     } else if (month === "7") {
//       month = "08";
//     } else if (month === "8") {
//       month = "09";
//     }

//     if (day === "1") {
//       day = "01";
//     } else if (day === "2") {
//       day = "02";
//     } else if (day === "3") {
//       day = "03";
//     } else if (day === "4") {
//       day = "04";
//     } else if (day === "5") {
//       day = "05";
//     } else if (day === "6") {
//       day = "06";
//     } else if (day === "7") {
//       day = "07";
//     } else if (day === "8") {
//       day = "08";
//     } else if (day === "9") {
//       day = "09";
//     }
//     let x = d.toISOString().split("T");
//     return x[0] + " " + x[1].substr(0, 8);
//   }



//  //Select Claim deductible

 setDeductible() {
  this.deductibles = [];
  for (let i = 0; i < this.plans.length; i++) {
    if (this.plans[i].active === true) {
      for (let j = 0; j < this.plans[i].planTerms[0].deductible.length; j++) {
        this.deductibles.push(this.plans[i].planTerms[0].deductible[j].amount);
      }
      this.deductibles = this.deductibles.sort((a, b) => a - b);
      this.deductibles = [...new Set(this.deductibles)];
      this.currentDeductible = this.deductibles[0];
    }
  }

  if (this.deductibles.length > 0) {
    this.coverageTermsForm.get('deductible').setValue(this.deductibles[0]);
  }
}



//   getProperPricing(currentDeductible) {
//     if (brandInfo.multiDeductible) {
//       for (let i = 0; i < this.plans.length; i++) {
//         // if (this.plans[i].active === true) {
//           console.log('Current Active Plan', this.plans[i])
//           console.log('Chosen deductible', currentDeductible)
//           this.plans[i].plan.deductible = currentDeductible
//           const info = this.plans[i].planTerms[this.plans[i].term.termIndex].deductible.filter(item => {
//             return item.amount === currentDeductible && item.disappearing === this.disappearingDeductibleValue
//           })

//           if (info.length > 0) {
//             this.plans[i].term.disappearingDeductibleSelected = info[0].disappearing
//             this.plans[i].term.contractDealerCost = info[0].price
//             this.plans[i].term.productPlanSkuId = info[0].sku

//             let surcharges = 0
//             if (this.plans[i].term.commercialUseSelected) {
//               surcharges += this.productTabSetService.getPriceForSurcharge('Light Duty')
//             }

//             if (this.plans[i].term.commercialUseSelected) {
//               surcharges += this.productTabSetService.getPriceForSurcharge('Professional Services')
//             }

//             if (this.plans[i].term.liftKitSelected) {
//               surcharges += this.productTabSetService.getPriceForSurcharge('Lift Kit')
//             }

//             if (this.plans[i].term.greyMarketSelected) {
//               surcharges += this.productTabSetService.getPriceForSurcharge('Grey Market')
//             }

//             if (this.plans[i].term.snowPlowSelected) {
//               surcharges += this.productTabSetService.getPriceForSurcharge('Plow')
//             }

//             if (!this.plans[i].term.commercialUseSelected && !this.plans[i].term.liftKitSelected && !this.plans[i].term.greyMarketSelected && !this.plans[i].term.snowPlowSelected) {
//               this.plans[i].term.totalPrice = info[0].price
//             } else {
//               this.plans[i].term.totalPrice = Number(info[0].price) + surcharges
//             }


//             this.plans[i].term.price = brandInfo.brand === 'Hyundai' ? (Number(this.plans[i].term.totalPrice) * .20).toFixed(2).toString() : (Number(this.plans[i].term.totalPrice) * .10).toFixed(2).toString()

//             if (brandInfo.frontLoadTaxes) {
//               this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice)) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
//             } else {
//               this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice - Number(this.plans[i].term.price))) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
//             }

//             console.log('Plans2 this.plansssssss', this.plans)

//             setTimeout(() => {
//               // this.chosenPlan.emit(this.plans[i]);
//               // this.canSelect = true;
//             })

//             return
//           }
//         //}
//       }
//     } else {
//       for (let i = 0; i < this.plans.length; i++) {
//         if (this.plans[i].active === true) {
//           const info = this.plans[i].planTerms[this.plans[i].term.termIndex]

//           this.plans[i].term.contractDealerCost = info.price
//           this.plans[i].term.productPlanSkuId = info.productPlanSkuId

//           let surcharges = 0
//           if (this.plans[i].term.commercialUseSelected) {
//             surcharges += this.productTabSetService.getPriceForSurcharge('Light Duty')
//           }

//           if (this.plans[i].term.commercialUseSelected) {
//             surcharges += this.productTabSetService.getPriceForSurcharge('Professional Services')
//           }

//           if (this.plans[i].term.liftKitSelected) {
//             surcharges += this.productTabSetService.getPriceForSurcharge('Lift Kit')
//           }

//           if (this.plans[i].term.greyMarketSelected) {
//             surcharges += this.productTabSetService.getPriceForSurcharge('Grey Market')
//           }

//           if (this.plans[i].term.snowPlowSelected) {
//             surcharges += this.productTabSetService.getPriceForSurcharge('Plow')
//           }

//           if (!this.plans[i].term.commercialUseSelected && !this.plans[i].term.liftKitSelected && !this.plans[i].term.greyMarketSelected && !this.plans[i].term.snowPlowSelected) {
//             this.plans[i].term.totalPrice = info.price
//           } else {
//             this.plans[i].term.totalPrice = Number(info.price) + surcharges
//           }


//           this.plans[i].term.price = brandInfo.brand === 'Hyundai' ? (Number(this.plans[i].term.totalPrice) * .20).toFixed(2).toString() : (Number(this.plans[i].term.totalPrice) * .10).toFixed(2).toString()

//           if (brandInfo.frontLoadTaxes) {
//             this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice)) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
//           } else {
//             this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice - Number(this.plans[i].term.price))) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
//           }
//           setTimeout(() => {
//             // this.chosenPlan.emit(this.plans[i]);
//             // this.canSelect = true;
//           })

//           return
//         }
//       }
//     }
//     console.log('plans2 plans', this.plans)
//   }

  toggleDisappearing() {
    this.disappearingDeductibleValue = !this.disappearingDeductibleValue
    this.updatePlans()
  }


  chooseDeductible(deductible) {

    if (deductible == 100 && this.disappearingDeductible) {
      this.disappearingDeductiblePanel = true;
    } else {
      this.disappearingDeductiblePanel = false;
    }
    setTimeout(() => {
      this.disappearingDeductibleValue = false;
      })
  }


  ngOnDestroy() {
    if (this.appConfigSubscription) {
      this.appConfigSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
