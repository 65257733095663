import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { EMPTY, throwError } from 'rxjs';
import { map, switchMap, mapTo, catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import { accountLoaded, accountUpdated, updateContactStateOn, updateBillingStateOn, updatePaymentStateOn, loadPaylink, noAccountFound, updateBillingInformation, getAccountSFIDSuccess, getAccountSFIDFailed, loadSPPHistory } from '../_actions/account.actions'
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { hideInlineLoader, hideInlineLoaderContact, hideInlineLoaderPayment, hideInlineLoaderBilling, hideInlineLoaderBillingInformation } from '../_actions/inline-loader.actions'
import { Store } from '@ngrx/store';
import { OrderService } from '../_services/order.service';
import { PaymentService } from '../_services/payment-and-tax-service.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountID } from '../_models/account';

@Injectable()

export class AccountEffects {

    public getAccountByEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.GET_ACCOUNT_SFID),
            switchMap((action: any) => this.accountService.get_account_id(action)
                .pipe(
                    map(response => {
                        console.log('Response from GET ACCOUNT SFID', response);
                        let object: AccountID = {
                            d2cAccountId: response.data.accountId,
                        }
                        sessionStorage.setItem('tokenId', response.data.access_token)
                        this.store.dispatch(getAccountSFIDSuccess(object))
                        return hideInlineLoader();

                    }),
                    catchError((error) => {
                        this.store.dispatch(getAccountSFIDFailed())
                        this.store.dispatch(hideInlineLoader())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    public getAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.LOAD_ACCOUNT),
            switchMap((action: any) => this.orderService.get_order(action)
                .pipe(
                    map(order => {
                        console.log('OrderNumberFromGetAcccount', order);
                        this.store.dispatch(accountLoaded(order))
                        if (order.data[0].paymentInformation.accountNumber) {
                            let request: AccountID = {
                                paylinkAccountId: order.data[0].paymentInformation.accountNumber,
                                d2cAccountId: action.payload,
                                paymentService: order.data[0].paymentService
                            }
                            console.log('SPPPPPPPPREQUEST', request)
                            return loadPaylink(request)
                        } else {
                            this.router.navigate(['/my-account']);
                            return hideInlineLoader();
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(noAccountFound())
                        this.store.dispatch(hideInlineLoader())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    public updateBillingAddress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.UPDATE_BILLING_ADDRESS),
            switchMap((action: any) => this.paymentService.update_billing_address(action)
                .pipe(
                    map(order => {
                        this.store.dispatch(updateBillingStateOn())
                        return hideInlineLoader()
                    }),
                    catchError(() => EMPTY)
                )
            )
        )
    )

    // public updatePaymentInformatoon$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(ActionConfigurationManager.UPDATE_PAYMENT_INFORMATION),
    //         switchMap((action: any) => this.paymentService.update_payment_information(action.payment)
    //             .pipe(
    //                 map(order => {
    //                     this.store.dispatch(updatePaymentStateOn())
    //                     return updateBillingInformation(action.billing)
    //                 }),
    //                 catchError(() => EMPTY)
    //             )
    //         )
    //     )
    // )

    //NEW PAYMENT FUNCTION
    public updatePaymentInformatoon$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ActionConfigurationManager.UPDATE_PAYMENT_INFORMATION),
        switchMap((action: any) => this.paymentService.update_billing_information(action)
            .pipe(
                map(order => {
                  console.log('ORDERRRRRRRRRRRRR', order)
                  this.store.dispatch(updatePaymentStateOn())
                  this.store.dispatch(updateBillingStateOn())
                  return hideInlineLoader();
                    //
                    // return updateBillingInformation(action.billing)
                }),
                catchError(() => EMPTY)
            )
        )
    )
)

    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private orderService: OrderService,
        private paymentService: PaymentService,
        private store: Store<{}>,
        private router: Router
    ) { }
}
