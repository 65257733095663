import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Order, OrderResponse } from '../_models/order';
import { TaxResponse, TaxRequest, ComparisonRequest } from '../_models/tax';
import { PaymentResponse } from '../_models/payment';
import { AVSResponse } from '../_models/avs';
import { environment } from '../../environments/environment';
import brandInfo from '../../environments/brand.json'
import { GatewayService } from './gateway.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  // Set the API Base URL

  constructor(
    private httpClient: HttpClient,
    private gatewayService: GatewayService,
    private loggingService: LoggingService) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }

  // HttpClient API get() method => Address Verification
  verify_address(address): Observable<AVSResponse> {
    return this.httpClient.get<AVSResponse>(this.gatewayService.baseUrl + 'dtc-payment-srvc/verifyCustomerAddress/?street1=' + encodeURIComponent(address.street1) + '&city=' + encodeURIComponent(address.city) + '&state=' + encodeURI(address.state) + '&postalCode=' + encodeURI(address.zip) + '&country=' + address.country + '&email=' + address.email + '&vin=' + address.vin + '&brandName=' + brandInfo.brand, this.httpOptions)
      .pipe(
        // map((result => result.data)),
        retry(1),
        catchError(this.loggingService.handleError)
      )
  }


  //HttpClient API get() method => Tax Service
  tax(object: TaxRequest): Observable<TaxResponse> {
    return this.httpClient.post<TaxResponse>(this.gatewayService.baseUrl + 'dtc-payment-srvc/tax', {
      opportunityId: object.opportunityId,
      city: object.city,
      country: object.country,
      street1: object.street1,
      state: object.state,
      firstName: object.firstName,
      lastName: object.lastName,
      email: object.email,
      phoneNumber: object.phoneNumber,
      postalCode: object.postalCode,
      brandName: object.brandName,
      vin: object.vin,
      items: object.items,
      downPayment: object.downPayment,
      installments: object.installments === 0 ? 1 : object.installments
    })
      .pipe(
        retry(1),
        catchError(this.loggingService.handleError)
      )
  }

  vertexTax(object: TaxRequest): Observable<TaxResponse> {
    return this.httpClient.post<TaxResponse>(`${environment.apiUrl}/v1/api/tax/getTaxes/vertex/draft`, {
      opportunityId: null,
      city: object.city,
      country: object.country,
      street1: object.street1,
      state: object.state,
      firstName: object.firstName,
      lastName: object.lastName,
      email: object.email,
      phoneNumber: object.phoneNumber,
      postalCode: object.postalCode,
      brandName: object.brandName,
      vin: object.vin,
      items: object.items,
      downPayment: object.downPayment,
      installments: object.installments === 0 ? 1 : object.installments
    })
      .pipe(
        retry(1),
        catchError(this.loggingService.handleError)
      )
  }

  storeComparison(object: ComparisonRequest): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/api/tax/storeComparison`, {
      subtotal: object.subtotal,
      state: object.state,
      avalaraTotalTax: object.avalaraTotalTax,
      vertexTotalTax: object.vertexTotalTax,
      product: object.product,
      address: object.address
    })
  }

  purchaseContractAccount(object): Observable<any> {
    return this.httpClient.post<any>(this.gatewayService.baseUrl + 'dtc-payment-srvc/purchaseContract', {
      contract: object.contract,
      createAccount: object.createAccount,
      order: object.order,
      payment: object.payment,
      appFlowState: sessionStorage.getItem('appFlowState')
    })
  }

  purchaseContractGuest(object): Observable<any> {
    return this.httpClient.post<any>(this.gatewayService.baseUrl + 'dtc-payment-srvc/purchaseContract', {
      contract: object.contract,
      order: object.order,
      payment: object.payment,
      createAccount: object.createAccount,
      shoppingCartRequest: object.shoppingCartRequest,
      appFlowState: sessionStorage.getItem('appFlowState')

    })
  }

  purchaseContractChange(object): Observable<any> {
    return this.httpClient.post<any>(this.gatewayService.baseUrl + 'dtc-payment-srvc/purchaseContract', {
      contract: object.contract,
      createAccount: object.createAccount,
      order: object.order,
      payment: object.payment,
      appFlowState: sessionStorage.getItem('appFlowState')
    })
  }

  update_billing_address(object): Observable<any> {
    return this.httpClient.post<any>(this.gatewayService.baseUrl + 'dtc-payment-srvc/updateAddress', {
      accountNumber: object.accountNumber,
      address1: object.address1,
      address2: object.address2 || "",
      cellPhone: object.cellPhone,
      city: object.city,
      email: object.email,
      insured1: object.insured1,
      insured2: object.insured2,
      phone: object.phone,
      prefComMethod: object.prefComMethod,
      state: object.state,
      workpPhone: object.workpPhone,
      zipCode: object.zipCode,
      paymentService: object.paymentService
    })
  }

  update_payment_information(object): Observable<any> {
    return this.httpClient.post<any>(this.gatewayService.baseUrl + 'dtc-payment-srvc/billingMethodUpdateCC', {
      accountNumber: object.accountNumber,
      cc: object.cc,
      ccMonth: object.ccMonth,
      ccName: object.ccName,
      ccYear: object.ccYear,
      cvcValue: object.cvcValue,
      paymentService: object.paymentService
   })
  }

  update_billing_information(object): Observable<any> {
    return this.httpClient.post<any>(this.gatewayService.baseUrl + 'dtc-payment-srvc/updateBillingInformation', {
      accountNumber: object.billing.accountNumber,
      address1: object.billing.address1,
      address2: object.billing.address2 || "",
      cellPhone: object.billing.cellPhone,
      city: object.billing.city,
      email: object.billing.email,
      insured1: object.billing.insured1,
      insured2: object.billing.insured2,
      phone: object.billing.cellPhone,
      prefComMethod: object.billing.prefComMethod,
      state: object.billing.state,
      workpPhone: object.billing.workpPhone,
      zipCode: object.billing.zipCode,
      cc: object.payment.cc,
      ccMonth: object.payment.ccMonth,
      ccName: object.payment.ccName,
      ccYear: object.payment.ccYear,
      cvcValue: object.payment.cvcValue,
      paymentService: object.payment.paymentService
   })
  }
}
