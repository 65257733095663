import { Injectable } from "@angular/core";
import brandInfo from "../../environments/brand.json";
import { Store } from "@ngrx/store";
import * as SelectedPlanActions from "../states/planSelect/selectedPlan.actions";

@Injectable({
  providedIn: "root",
})
export class SelectedPlanService {
  constructor(public store: Store<{ selectedPlan: any }>) {}

  brandInfo: any;
  updatePricing(currentSelectedPlan, selectedCoverageTerm) {
    let currentSelectedPlan2 = currentSelectedPlan.selectedPlan;

    let termsData = currentSelectedPlan2.planTerms;

    let newTerm = {
      ...currentSelectedPlan2.term,
      termIndex: termsData[selectedCoverageTerm],
      numberOfPayments: Number(
        termsData[selectedCoverageTerm].paymentTerms[0].split(" ")[0]
      ),

      price: (Number(termsData[selectedCoverageTerm].planTermSKURate) / 10)
        .toFixed(2)
        .toString(),
      totalPrice: termsData[selectedCoverageTerm].planTermSKURate,
      productPlanSkuId: termsData[selectedCoverageTerm].productPlanSkuId,
    };

    newTerm.payment = brandInfo.frontLoadTaxes
      ? (Number(newTerm.totalPrice) / Number(newTerm.numberOfPayments))
          .toFixed(2)
          .toString()
      : (
          (Number(newTerm.totalPrice) - Number(newTerm.price)) /
          Number(newTerm.numberOfPayments)
        )
          .toFixed(2)
          .toString();

    currentSelectedPlan2 = {
      ...currentSelectedPlan2,
      term: newTerm,
    };

    this.store.dispatch(
      SelectedPlanActions.loadloadSelectedCoverageTerms({
        selectedPlanTerms: currentSelectedPlan2,
      })
    );

    console.log("Plan 222222osssssssssss", currentSelectedPlan2);
  }

  extractUniquePlanTerms(plans) {
    console.log("INTHEUNIQUEPLANS", plans);
    const uniqueTerms = new Set();
    const uniquePlanTerms = [];
    if (!plans || plans.length === 0) {
      return [];
    }

    plans.forEach((plan) => {
      if (!plan.planTerms) {
        return;
      }

      plan.planTerms.forEach((term) => {
        if (!term || !term.label) {
          console.log("Skipping invalid term:", term);
          return;
        }

        const termLabel = term.label;
        if (!uniqueTerms.has(termLabel)) {
          uniqueTerms.add(termLabel);
          uniquePlanTerms.push(term);
        }
      });
    });

    uniquePlanTerms.sort((a, b) => {
      const numA = parseInt(a.label.match(/\d+/)[0], 10);
      const numB = parseInt(b.label.match(/\d+/)[0], 10);
      return numA - numB;
    });

    console.log("uniquePlanTerms:", uniquePlanTerms);
    return uniquePlanTerms;
  }

  calcPaymentTerms(paymentTerms, totalPrice, price, brandInfo) {
    let numberOfPayments = paymentTerms[0].split(" ")[0];
    let payment = "";
    if (brandInfo.frontLoadTaxes) {
      payment = Number(Number(totalPrice) / Number(numberOfPayments))
        .toFixed(2)
        .toString();
    } else {
      payment = Number(
        (Number(totalPrice) - Number(price)) / Number(numberOfPayments)
      ).toFixed(2).toString();
    }

    return { numberOfPayments, payment };
  }


  updatePlansWithSingleDeductible(plans, selectedIndex, selectedPlan, brandInfo, singleDeductible) {
    const selectedIncomingTerm = selectedPlan?.planTerms[selectedIndex];

    const updatedPlans = plans.map((plan) => {
      const mutablePlan = JSON.parse(JSON.stringify(plan));



      const matchingTerm = mutablePlan.planTerms.find(
        (term) => term.label === selectedIncomingTerm?.label
      );

      if (matchingTerm) {
        let price = (matchingTerm.price / 10).toFixed(2).toString();
        let totalPrice = matchingTerm.price;

        let { numberOfPayments, payment } = this.calcPaymentTerms(
          mutablePlan.planTerms.find(planTerm => planTerm.label === selectedIncomingTerm.label).paymentTerms,
          totalPrice,
          price,
          brandInfo
        );
        mutablePlan.term = {
          // termIndex: mutablePlan.planTerms.indexOf(matchingTerm),
          deductible: singleDeductible,
          termIndex: matchingTerm.value,
          price: price,
          payment: payment,
          totalPrice: totalPrice,
          priceHeader: "As low as",
          numberOfPayments: numberOfPayments,
          productPlanSkuId: matchingTerm.productPlanSkuId,
          commercialUseSelected:  false,
          greyMarketSelected: false,
          snowPlowSelected: false,
          liftKitSelected: false,
          disappearingDeductibleSelected:
            plan.term.disappearingDeductibleSelected,
          contractDealerCost: totalPrice,
        };
      } else {
        mutablePlan.term =  null;
        console.log(
          `No matching label found in plan: ${mutablePlan.name} for label: ${selectedIncomingTerm?.label} on a plan ${JSON.stringify(mutablePlan.term)}`
        );
      }
      return mutablePlan;
    });

    this.store.dispatch(
      SelectedPlanActions.loadAllPlansBasedTerms({
        allPlansPlanBasedTerm: updatedPlans,
      })
    );

    return updatedPlans;
  }

  getCorrespondingDeductible(plan: any,  amount: any, isDisappearingDeductible) {
    return plan?.deductible?.filter(item => item.amount === amount && item.disappearing === isDisappearingDeductible);
  }


storingSelectedPlan(selectedIndex, selectedPlan, brandInfo, deductible, isDissapearingDeductible) {
  if (!selectedPlan) {
    return;
  }
  try {
    let price, totalPrice = 0
    let sku: any

    const mutablePlan = JSON.parse(JSON.stringify(selectedPlan));
    const selectedIncomingTerm = mutablePlan?.planTerms[selectedIndex];

    const matchingTerm = mutablePlan.planTerms.find(
      (term) => term.label === selectedIncomingTerm?.label
    );

    if(brandInfo.multiDeductible) {
      const correspondingDeductible = this.getCorrespondingDeductible(matchingTerm, deductible, isDissapearingDeductible);
      console.log('correspondingDeductible:', correspondingDeductible);
      price = (correspondingDeductible[0].price / 10).toFixed(2).toString();
      totalPrice = correspondingDeductible[0].price;
      sku = correspondingDeductible[0].sku;
    } else {
      price = (matchingTerm.price / 10).toFixed(2).toString();
      totalPrice = matchingTerm.price;
      sku = matchingTerm.productPlanSkuId;
    }

    let { numberOfPayments, payment } = this.calcPaymentTerms(
      mutablePlan.planTerms.find(planTerm => planTerm.label === selectedIncomingTerm.label).paymentTerms,
      totalPrice,
      price,
      brandInfo
    );
    console.log('numberOfPayments:', numberOfPayments, 'payment:', payment);

    const newTerm = {
      deductible: deductible,
      termIndex: matchingTerm.value,
      price: price,
      payment: payment,
      totalPrice: totalPrice,
      priceHeader: "As low as",
      numberOfPayments: numberOfPayments,
      productPlanSkuId: sku,
      commercialUseSelected:  false,
      greyMarketSelected: false,
      snowPlowSelected: false,
      liftKitSelected: false,
      disappearingDeductibleSelected: isDissapearingDeductible,
      contractDealerCost:  totalPrice,
    };

    // Checking if the term needs to be updated
    if (JSON.stringify(mutablePlan.term) !== JSON.stringify(newTerm)) {
      mutablePlan.term = newTerm;
      this.store.dispatch(SelectedPlanActions.loadCurrentSelectedPlan({ currentSelectedPlan: mutablePlan }));
      console.log('Dispatched action');
    } else {
      console.log('No changes skipping dispatch');
    }
  } catch (error) {
    console.error('Error in storingSelectedPlan:', error);
  }
}


  // TODO ADD COMMENTS
  updatePlansWithMultiDeductible(plans, selectedIndex, selectedPlan, brandInfo, deductible, isDisappearingDeductible) {
    const selectedIncomingTerm = selectedPlan?.planTerms[selectedIndex];

    const updatedPlans = plans.map((plan) => {
      const mutablePlan = JSON.parse(JSON.stringify(plan));

      // const selectedIncomingTerm = selectedPlan?.planTerms[selectedIndex];


      const matchingTerm = mutablePlan.planTerms.find(
        (term) => term.label === selectedIncomingTerm?.label
      );

      if (matchingTerm) {
        const  correspondingDeductible = this.getCorrespondingDeductible(matchingTerm, deductible, isDisappearingDeductible);
        let price = (correspondingDeductible[0].price / 10).toFixed(2).toString();
        let totalPrice = correspondingDeductible[0].price;

        let { numberOfPayments, payment } = this.calcPaymentTerms(
          mutablePlan.planTerms.find(planTerm => planTerm.label === selectedIncomingTerm.label).paymentTerms,
          totalPrice,
          price,
          brandInfo
        );
        mutablePlan.term = {
          // termIndex: mutablePlan.planTerms.indexOf(matchingTerm),
          deductible: deductible,
          termIndex: matchingTerm.value,
          price: price,
          payment: payment,
          totalPrice: totalPrice,
          priceHeader: "As low as",
          numberOfPayments: numberOfPayments,
          productPlanSkuId: matchingTerm.productPlanSkuId,
          commercialUseSelected:  false,
          greyMarketSelected: false,
          snowPlowSelected: false,
          liftKitSelected: false,
          disappearingDeductibleSelected: plan.term.disappearingDeductibleSelected,
          contractDealerCost: totalPrice,
        };
      } else {
        mutablePlan.term =  null;
        console.log(
          `No matching label found in plan: ${mutablePlan.name} for label: ${selectedIncomingTerm?.label} on a plan ${JSON.stringify(mutablePlan.term)}`
        );
      }
      return mutablePlan;
    });

    this.store.dispatch(
      SelectedPlanActions.loadAllPlansBasedTerms({
        allPlansPlanBasedTerm: updatedPlans,
      })
    );

    // return updatedPlans;
  }

  updateAllPlansWithDeductible(plans, deductibleAmount, termIndex) {
    const updatedPlans = plans.map((plan) => {
      const updatedPlan = { ...plan };
      if (plan.planTerms && plan.planTerms.length > termIndex) {
        const term = plan.planTerms[termIndex];
        const deductible = term.deductible.find(
          (d) => d.amount === deductibleAmount
        );
        if (deductible) {
          updatedPlan.term = {
            ...updatedPlan.term,
            price: (deductible.price / 100).toFixed(2),
            totalPrice: deductible.price,
            deductibleAmount: deductible.amount,
            deductibleDisappearing: deductible.disappearing,
            sku: deductible.sku,
          };
        }
      }

      return updatedPlan;
    });

    console.log("Updated Plans Array: ", updatedPlans);
    this.store.dispatch(
      SelectedPlanActions.loadAllPlansBasedTerms({
        allPlansPlanBasedTerm: updatedPlans,
      })
    );

    return updatedPlans;
  }
}
