import { PrismicEffects } from './../_effects/prismic.effects';
import { NgModule, Optional, SkipSelf, Injectable } from '@angular/core';
// import { PrismicServiceProvider } from '../_services/prismic.service.provider';
import { HttpClientModule } from '@angular/common/http';
import { ProductHomeResolve } from '../_services/product-home.resolve';
import { HomeResolve } from '../_services/home.resolve';
import { PrismicResolve } from '../_services/prismic.resolve';
import { HiwResolve } from '../_services/hiw.resolve';
import { PpmResolve } from '../_services/ppm.resolve';
import { StoreModule } from '@ngrx/store';

// Reducers
import { leadReducer } from '../_reducers/lead.reducer';
import { vehicleReducer } from '../_reducers/vehicle.reducer';
import { ratesReducer } from '../_reducers/rates.reducer';
import { cartReducer } from '../_reducers/cart.reducer';
import { registrationReducer } from '../_reducers/registration.reducer';
import { vinDecodeReducer } from '../_reducers/vinDecode.reducer';
import { emailReducer } from '../_reducers/email.reducer';
import { billingReducer } from '../_reducers/billing.reducer';
import { planReducer } from '../_reducers/plan.reducer';
import { orderReducer } from '../_reducers/order.reducer';
import { avsReducer } from '../_reducers/avs.reducer';
import { stateReducer } from '../_reducers/state.reducer';
import { taxReducer } from '../_reducers/tax.reducer';
import { accountReducer } from '../_reducers/account.reducer';
import { paylinkReducer } from '../_reducers/paylink.reducers';
import { inlineLoaderReducer } from '../_reducers/inline-loader.reducer'
import { inlineLoaderMyAccountContactReducer } from '../_reducers/inline-loader-my-account-contact.reducer'
import { inlineLoaderMyAccountBillingReducer } from '../_reducers/inline-loader-my-account-billing.reducer'
import { inlineLoaderMyAccountPaymentReducer } from '../_reducers/inline-loader-my-account-payment.reducer'
import { tokenReducer } from '../_reducers/token.reducer'
import { vinDecodeStateReducer } from '../_reducers/vinDecodeState.reducer'
import { avsStateReducer } from '../_reducers/avs-state.reducer'
import { confirmationNumberReducer } from '../_reducers/confirmation.reducer'
import { cartDeleteItemReducer } from '../_reducers/cart-delete-item.reducer';
import { sppPaymentHistoryReducer } from '../_reducers/sppPaymentHistory.reducer';


// Effects
import { EffectsModule } from '@ngrx/effects';
import { RateEffects } from '../_effects/rates.effects';
import { LeadEffects } from '../_effects/lead.effects';
import { VinDecodeEffects } from '../_effects/vinDecode.effects';
import { CartEffects } from '../_effects/cart.effects';
import { OrderEffects } from '../_effects/order.effects';
import { AVSEffects } from '../_effects/avs.effects';
import { TaxEffects } from '../_effects/tax.effects';
import { AccountEffects } from '../_effects/account.effects';
import { PaylinkEffects } from '../_effects/paylink.effects';
import { ProspectEffects } from '../_effects/prospect.effects';



import { verifyAccountReducer } from '../_reducers/verify-account.reducer';
import { verifyCodeReducer } from '../_reducers/verify-code.reducer';
import { VerifyAccountEffects } from '../_effects/verify-account.effects';
import { modalComponentStateReducer } from '../_reducers/modal-component.reducer';
import { urlParamsReducer } from '../_reducers/urlParams.reducer';
import { appFlowStateReducer } from '../_reducers/app-flow-state.reducer';
import { PDFEffects } from '../_effects/pdf.effects';
import { pdfReducer } from '../_reducers/pdf.reducer';
import { sliceRateMappingReducer } from '../_reducers/slice-rate-mapping.reducer';
import { verifyCodeStateReducer } from '../_reducers/verify-code-state-reducer';
import { updateBillingStateReducer } from '../_reducers/update-billing-state.reducer';
import { updatePaymentStateReducer } from '../_reducers/update-payment-state.reducer';
import { updateContactStateReducer } from '../_reducers/update-contact-state.reducer';
import { orderStateReducer } from '../_reducers/order-state.reducer';
import { orderStateInlineLoaderReducer } from '../_reducers/order-state-inline-loader.reducer';
import { inlineLoaderPreferenceCenterReducer } from '../_reducers/inline-loader-preference-center.reducer';
import { preferenceCenterReducer } from '../_reducers/preference-center.reducer';
import { paymentHistoryReducer } from '../_reducers/payment-history.reducer';
import { sessionReducer } from '../_reducers/session.reducer';
import { refIDReducer } from '../_reducers/reference-id.reducer';
import { taxDownpaymentReducer } from '../_reducers/tax-downpayment.reducer';
import { taxRemainingReducer } from '../_reducers/tax-remaining.reducer';
import { VerifyVinEffects } from '../_effects/verify-vin.effects';
import { verifyVinReducer } from '../_reducers/verify-vin.reducer';
import { prismicPDFReducer } from '../_reducers/prismic-pdf.reducer';
import { VehicleEffects } from '../_effects/vehicle.effects';
import { prismicReducer } from '../_reducers/prismic.reducer';
import { productDisclaimerReducer } from '../_reducers/product-disclaimers.reducer';
import { opportunityReducer } from '../_reducers/opportunity.reducer';
import { inlineLoaderModalReducer } from '../_reducers/inline-loader-modal.reducer';
import { CartService } from '../_services/cart.service';
import { ProductTabsetService } from '../_services/product-tabset.service';
import { PagesService } from '../_services/pages.service';
import { tokenRefreshReducer } from '../_reducers/token-refresh.reducer';
import { TokenRefreshEffects } from '../_effects/token-refresh.effects';
import { cookiesAcceptedReducer } from '../_reducers/cookie-acceptance.reducer';
import { paymentOptionsMaxReducer } from '../_reducers/payment-options.reducer';
import { singleFlowProductChangedReducer } from '../_reducers/single-flow-product-changed.reducer';
import { multiProductFlowPaymentTermsChangedReducer } from '../_reducers/multi-product-flow-payment-terms-changed.reducer';
import { abandonCartTriggerReducer } from '../_reducers/abandon-cart.reducer';
import { AbandonCartTriggerEffects } from '../_effects/abandon-cart.effects';
import { oktaRegistrationReducer } from '../_reducers/okta-registration.reducer';
import { oktaPasswordReducer } from '../_reducers/okta-password.reducer';
import { OktaEffects } from '../_effects/okta.effects';
import { oktaUserReducer } from '../_reducers/okta-user.reducer';
import { serviceAgreementReducer } from '../_reducers/serviceAgreement.reducer';
import { paymentPlanAgreementReducer } from '../_reducers/paymentPlanAgreement.reducer';
import { verifyVehicleVinInfoReducer } from '../_reducers/verifyVehicleVinInformation.reducer';
import { vertexTaxReducer } from '../_reducers/vertex-tax.reducer';
import { VertexTaxEffects } from '../_effects/vertex-tax.effects';
import { paypalReducer } from '../_reducers/paypal.reducer';
import { prospectReducer } from '../_reducers/prospect.reducer';
import { dealerValidationReducer } from '../_reducers/dealer.reducer';
import { DealerEffects } from '../_effects/dealer.effects';
import { S3ManagementEffects } from '../_effects/s3-management.effects';
import { s3ManagementValidationReducer } from '../_reducers/s3-management.reducer';
import { checkActiveOrder } from '../_actions/check-active-order.actions';
import { activeOrderReducer } from '../_reducers/check-active-order.reducer';
import { ActiveOrderEffects } from '../_effects/check-active-order.effects';
import { customerReducer } from '../_reducers/customer.reduer';
import { PaypalTransactionEffects } from '../_effects/paypal-transaction.effect';
import { PrismicReducers } from '../states/prismic2/prismic2.reducer';
import { PrismicEffects2 } from '../states/prismic2/prismic2.effects';
import { SelectedPlanReducers } from '../states/planSelect/selectedPlan.reducer';
import { EmailEffects } from '../states/marketingEmail/marketingEmail.effects';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    StoreModule.forRoot(
    {
        lead: leadReducer,
        vehicle: vehicleReducer,
        rates: ratesReducer,
        cart: cartReducer,
        registration: registrationReducer,
        vinDecode: vinDecodeReducer,
        email: emailReducer,
        billing: billingReducer,
        plan: planReducer,
        order: orderReducer,
        avs: avsReducer,
        states: stateReducer,
        tax: taxReducer,
        account: accountReducer,
        paylink: paylinkReducer,
        paypalIntergration: paypalReducer,
        inlineLoader: inlineLoaderReducer,
        inlineLoaderMyAccountContact: inlineLoaderMyAccountContactReducer,
        inlineLoaderMyAccountBilling: inlineLoaderMyAccountBillingReducer,
        inlineLoaderMyAccountPayment: inlineLoaderMyAccountPaymentReducer,
        token: tokenReducer,
        vinDecodeState: vinDecodeStateReducer,
        verifyAccount: verifyAccountReducer,
        verifyCode: verifyCodeReducer,
        modalComponentState: modalComponentStateReducer,
        urlParams: urlParamsReducer,
        appFlowState: appFlowStateReducer,
        pdf: pdfReducer,
        serviceAgreementPdf: serviceAgreementReducer,
        getPaymentPlanAgreement: paymentPlanAgreementReducer,
        verifyVehicleVinInfo: verifyVehicleVinInfoReducer,
        prismicPDF: prismicPDFReducer,
        avsState: avsStateReducer,
        sliceRateMapping: sliceRateMappingReducer,
        verifyCodeState: verifyCodeStateReducer,
        updateBillingState: updateBillingStateReducer,
        updatePaymentState: updatePaymentStateReducer,
        updateContactState: updateContactStateReducer,
        orderState: orderStateReducer,
        orderStateInlineLoader: orderStateInlineLoaderReducer,
        inlineLoaderPreferenceCenter: inlineLoaderPreferenceCenterReducer,
        preferenceCenter: preferenceCenterReducer,
        confirmation: confirmationNumberReducer,
        paymentHistory: paymentHistoryReducer,
        refID: refIDReducer,
        session: sessionReducer,
        taxDownPayment: taxDownpaymentReducer,
        taxRemaining: taxRemainingReducer,
        verifyVin: verifyVinReducer,
        prismic: prismicReducer,
        productDisclaimers: productDisclaimerReducer,
        opportunity: opportunityReducer,
        inlineLoaderModal: inlineLoaderModalReducer,
        tokenRefresh: tokenRefreshReducer,
        cookiesAccepted: cookiesAcceptedReducer,
        cartDeleteItem: cartDeleteItemReducer,
        paymentOptionsMax: paymentOptionsMaxReducer,
        singleFlowProductChanged: singleFlowProductChangedReducer,
        inlineLoaderMyAccountBillingInformation: inlineLoaderMyAccountBillingReducer,
        multiProductFlowPaymentTermsChanged: multiProductFlowPaymentTermsChangedReducer,
        abandonCartTrigger: abandonCartTriggerReducer,
        oktaRegistration: oktaRegistrationReducer,
        oktaPassword: oktaPasswordReducer,
        oktaUser: oktaUserReducer,
        vertexTaxReducer: vertexTaxReducer,
        commercialPlan: planReducer,
        prospect: prospectReducer,
        dealer: dealerValidationReducer,
        s3Management: s3ManagementValidationReducer,
        activeOrder: activeOrderReducer,
        sppHistoryPaymentDetail: sppPaymentHistoryReducer,
        customer: customerReducer,
        primics2: PrismicReducers,
        selectedPlan: SelectedPlanReducers,
        marketingEmail: modalComponentStateReducer

    }
  ),
  EffectsModule.forRoot(
    [
        RateEffects,
        LeadEffects,
        VehicleEffects,
        VinDecodeEffects,
        CartEffects,
        OrderEffects,
        AVSEffects,
        TaxEffects,
        AccountEffects,
        PaylinkEffects,
        VerifyAccountEffects,
        PDFEffects,
        VerifyVinEffects,
        PrismicEffects,
        TokenRefreshEffects,
        AbandonCartTriggerEffects,
        OktaEffects,
        VertexTaxEffects,
        ProspectEffects,
        DealerEffects,
        S3ManagementEffects,
        ActiveOrderEffects,
        PaypalTransactionEffects,
        PrismicEffects2,
        EmailEffects
    ]
  )],
  exports: [
    HttpClientModule
  ],
  providers: [
    // PrismicServiceProvider,
    ProductHomeResolve,
    HomeResolve,
    PrismicResolve,
    HiwResolve,
    PpmResolve,
    CartService,
    ProductTabsetService,
    PagesService
  ]
})

export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
