import { hideInlineLoader, showInlineLoader } from "./../_actions/inline-loader.actions";
import { Component, OnInit, HostListener, Input, OnDestroy } from "@angular/core";
import * as FollowAnalytics from "followanalytics";
import { Router } from "@angular/router";
import { LeadResponse, Lead } from "../_models/lead";
import { Subscription, Observable, merge, takeUntil } from "rxjs";
import { VinDecodeResponse, Vehicle } from "../_models/vehicle";
import { DataLookupService } from "../_services/data-lookup.service";
import { ProspectService } from "../_services/prospect-service.service";
import { MakeResponse } from "../_models/make";
import { ModelResponse } from "../_models/model";
import { TrimResponse, Trim } from "../_models/trim";
import { UntypedFormGroup, FormBuilder, FormControl } from "@angular/forms";
import { VehicleService } from "../_services/vehicle.service";
import { HomeService } from "./../_services/home.service";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { ModalComponent } from "../modal/modal.component";
import { Title, Meta } from "@angular/platform-browser";

/* DATA STORE IMPORTS */
import { Store, select } from "@ngrx/store";
import { loadVehicle } from "../_actions/vehicle.actions";
import { Registration } from "../_models/registration";
import { loadRegistrationState } from "../_actions/registration.actions";
import { EmailInfo } from "../_models/emailInfo";
import { loadEmail } from "../_actions/email.actions";
import { AVSRequest } from "../_models/avs";
import { loadStates } from "../_actions/states.actions";
import { loadLead, loadLeadOpportunity } from "../_actions/lead.actions";
import { AccountContactBrand } from "../_models/accountContact";
import { RatesResponse, RatesRequest } from "../_models/rates";
import { guest, account } from "../_actions/app-flow-state.actions";
import { URLParameters } from "../_models/urlParams";
import { saveUrlParams } from "../_actions/urlParams.actions";
import { resetReferenceID } from "../_actions/reference-id.actions";
import { AppConfigService } from "../appconfig/appconfig.service";
import brandInfo from "../../environments/brand.json";
import { loadPrismic } from "../_actions/prismic.actions";
import { prismicContent, prismicLandingPage } from "../_models/prismic";
import { ratesError } from "../_actions/modal-component.actions";
import { loadProductDisclaimers } from "../_actions/product-disclaimers.actions";
import { Product, ProductDisclaimers } from "../_models/product";
import { Opportunity } from "../_models/account";
import { resetRates } from "../_actions/rates.actions";
import { PagesService } from "../_services/pages.service";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
import * as PrismicActions from '../states/prismic2/prismic2.actions';
import { AppState } from "../states/app.state";


declare var gtag;

@Component({
  selector: "app-product-home",
  templateUrl: "./product-home.component.html",
  styleUrls: ["./product-home.component.scss"],
})
export class ProductHomeComponent implements OnInit, OnDestroy {
  leadSubscription: Subscription;
  pageId: string;
  inlineLoader$: Observable<any>;
  appFlowState$: Observable<any>;
  prismicData$: Observable<any>;
  vehicleData$: Observable<any>;

  modalRef: MDBModalRef;

  slices: any = [];
  loading = true;
  error: any;
  uid: string;
  lang: string;
  homeData: any;
  odometerSelect: any;
  prismicData: any;
  showOutOfWarranty = false;
  // branding: any;

  // For ngSwitch - Prod gets hung up on an undefined view property
  view: any;

  public leadData = new LeadResponse();
  private leadSub = new Subscription();
  private lead$: Observable<Lead>;

  private vehicleSub = new Subscription();
  private prismicSub = new Subscription();

  vehicleMake: any;
  vehicleModel: any;
  vehicleTrim: any;
  vehicleYear: any;
  vehicleVin: any;
  vehicleObject: any;

  customerRegistration: Registration = {
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };

  customerEmailInfo: EmailInfo = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    initials: "",
  };

  customerVehicle: Vehicle = {
    isElectric: false,
    make: "",
    makeExternalId: "",
    makeSfId: "",
    mileage: 0,
    model: "",
    modelExternalId: "",
    registeredState: "",
    modelSfId: "",
    modelNumber: "",
    purchaseDate: "",
    referenceNumber1: "",
    referenceNumber2: "",
    referenceNumber3: "",
    referenceNumber4: "",
    trim: "",
    trimExternalId: "",
    trimSfId: "",
    vehicleCondition: "",
    vehicleExternalId: "",
    vehicleSfId: "",
    vehicleType: "",
    vin: "",
    year: "",
  };

  public makeData = new MakeResponse();
  public modelData = new ModelResponse();
  public trimData = new TrimResponse();
  public vinDecodeData = new VinDecodeResponse();
  private vinDecodeSub = new Subscription();

  public customerLeadData = new Lead();

  keyword = "modelTrimCombo";
  data = [];

  vinDecodeForm: UntypedFormGroup;

  avsRequest: AVSRequest = {
    city: "",
    state: "",
    country: "",
    email: "",
    street1: "",
    zip: "",
    vin: "",
    brandName: "",
  };

  currentBrand = brandInfo.brand;
  modalComponentState$: Observable<any>;
  leadModalSub: Subscription;
  ratesSub: Subscription;
  pageClass: string;
  useNewRatesPage = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vehicle: DataLookupService,
    private lead: ProspectService,
    private vin: VehicleService,
    private home: HomeService,
    private pageService: PagesService,
    private modalService: MDBModalService,
    private appConfig: AppConfigService,
    private titleService: Title,
    private metaService: Meta,
    private dialog: MatDialog,

    private store: Store<{
      lead: Lead;
      vehicle: Vehicle;
      inlineLoader: any;
      appFlowState: any;
      prismic: any;
      modalComponentState: any;
      rates: RatesResponse;
      primics2: any;
    }>
  ) {
    this.route.queryParams.subscribe((params) => {
      // console.log("Snapshot pageurl: ", this.route.snapshot.params.pageurl)
      this.store.dispatch(showInlineLoader());
      let urlParamsObject: URLParameters = {
        prid: "",
        pageType: "",
        product: "",
        pageId: "",
        pageurl: "",
        parenturl: "",
        AgentId: "",
        oid: "",
        lang: "",
        isElectric: "non-electric",
        emailName: "",
        campaign: ""
      };
      if (params) {
        console.log("Params: ", params);
        console.log("Snapshot params", this.route.snapshot.params);
        console.log("Snapshot Data", this.route.snapshot.data);
        console.log("Current URL", this.router.url);

        urlParamsObject.pageId = this.route.snapshot.params.page_id || "";
        urlParamsObject.pageurl = this.route.snapshot.params.pageurl || "";
        urlParamsObject.parenturl = this.route.snapshot.params.parenturl || "";
        urlParamsObject.emailName = params['email_name'] || "";
        urlParamsObject.campaign = params['campaign'] || "";

        urlParamsObject.AgentId = params["AgentId"] || "";

        urlParamsObject.prid = params["prid"] || "";
        urlParamsObject.product = this.route.snapshot.data.productType || "";
        urlParamsObject.pageType = this.route.snapshot.data.pageType || "";
        urlParamsObject.oid = params["oid"] || "";

        if (params["re"] === true || params["re"] === "true") {
          console.log("Error occurred. Showing modal.");
          this.ratesError();
        } else if (this.pageService.isRatingPage(this.router.url)) {
          this.pageService.setRatingPage(this.router.url.replace("/", ""));
          this.loadLandingPage(urlParamsObject);
        } else {
          let landingPage = this.parseLandingUri(this.router.url);
          this.pageService.setLandingPage(landingPage)
          this.loadLandingPage(urlParamsObject);
        }

        if(params["preview"]){
          console.log("%c ***** Entering Preview Mode ******", "color: #1ECBE1");
          this.home.loadPreviewMode();
        }
      }
    });

    route.params.subscribe(params => {
      const modalOptions = {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        scroll: true,
        ignoreBackdropClick: false,
        class: "modal-dialog-centered",
        containerClass: "",
        animated: true,
        data: {},
      };
    })
  }


  parseLandingUri(routerUrl){

    let parsedRouter = this.router.url.replace("/", "");
    let splitRoute = parsedRouter.split("?");
    if(splitRoute.length > 0){
      return splitRoute[0];
    }

    return parsedRouter;
  }

  openModal(data) {
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      scroll: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered",
      containerClass: "",
      animated: true,
      data: {
        heading: "Let's Meet Your Car",
        content: {
          heading: "Content heading",
          description: "Content description",
        },
        vehicle: {
          year: this.vehicleYear,
          model: this.vehicleModel,
          trim: this.vehicleTrim,
          vin: this.vehicleVin,
        },
        leadData: this.leadData,
      },
    };

    this.modalRef = this.modalService.show(ModalComponent, modalOptions);

    const modalRefSubscription = this.modalRef.content.action.subscribe((result: any) => {
      // console.log(result);
      modalRefSubscription.unsubscribe();
    });
  }

  loadDisclaimers(slices) {
    let dataObject: Product = {
      productName: "",
      disclaimers: [],
      toolTips: [],
    };

    if (slices) {
      for (let i = 0; i < slices.length; i++) {
        if (slices[i] && slices[i].type === "product") {
          for (let j = 0; j < slices[i].fields.length; j++) {
            //Grab product title
            dataObject.productName = slices[i].fields[j].product.product_title[0].text;

            // Loop through disclaimers
            for (let k = 0; k < slices[i].fields[j].product.state_disclaimers.length; k++) {
              let disclaimers: ProductDisclaimers = {
                type: slices[i].fields[j].product.state_disclaimers[k].description[0].type,
                text: slices[i].fields[j].product.state_disclaimers[k].description[0].text,
                state: slices[i].fields[j].product.state_disclaimers[k].state,
                spans: slices[i].fields[j].product.state_disclaimers[k].description[0].spans,
              };
              dataObject.disclaimers.push(disclaimers);
            }
            //Loop through toolTips
            /**
             * Coming sooon
             */
          }
          //Send to store and return
          this.store.dispatch(loadProductDisclaimers(dataObject));
        }
      }
    }
  }

  changePageMetadata(pageMeta) {
    if (pageMeta.meta_title) {
      this.titleService.setTitle(pageMeta.meta_title);
    }

    if (pageMeta.meta_description) {
      this.metaService.updateTag({ name: "description", content: pageMeta.meta_description });
    } else {
      this.metaService.removeTag('name="description"');
    }

    //Add robots
    if (pageMeta.robots) {
      console.log("Found robots metadata: ", pageMeta.robots);
      this.metaService.updateTag({ name: "robots", content: pageMeta.robots });
      window.sessionStorage.setItem('robots', pageMeta.robots)
    } else {
      this.metaService.updateTag({ name: "robots", content: "noindex" });
      window.sessionStorage.setItem('robots','noindex')
    }

  }

  initStoreData() {
    // Create Observables
    this.inlineLoader$ = this.store.select((store) => store.inlineLoader);
    this.appFlowState$ = this.store.select((store) => store.appFlowState);
    this.prismicData$ = this.store.select((store) => store.prismic);
    this.vehicleData$ = this.store.select((store) => store.vehicle);

    // Create Subscription
    this.prismicSub = this.prismicData$.subscribe((prismicData) => {
      if (prismicData) {
        this.slices = prismicData.content;
        this.loadDisclaimers(this.slices);
        this.changePageMetadata(prismicData.pageMeta);
        this.pageClass = prismicData.pageClass;
      }
      console.log("Got slices from store->prismic ngrx slice", this.slices);
    });
  }

  loadPageDataBasedOnLead() {
    this.lead$ = this.store.select((store) => store.lead);
    this.modalComponentState$ = this.store.select((store) => store.modalComponentState);
  }

  ngOnInit() {


    // Show Inline Loader
    this.store.dispatch(showInlineLoader());

    // Initialize Ngrx Store Data
    this.initStoreData();

    // Load Page Data Based On Lead
    //this.loadPageDataBasedOnLead();

    // Reset Ref Id
    // this.store.dispatch(resetReferenceID());

    //Get Rates
    this.getRatesFromStore();

    if (this.pageService.isRatingPage(this.router.url)) { // Only show OutOfWarranty on a ratings page
      // Need logic to show OutOfWarranty banner
      let _warranty = sessionStorage.getItem('hasFactoryWarranty');
      if (_warranty === 'false' && brandInfo.brand !== "Hyundai" && brandInfo.brand !== "Lambo") {
        this.showOutOfWarranty = true;
      }
    }
  }

  loadPageFromUrlParams(pageurl, _urlParams, isElectric) {
    const prismicLandingPage: prismicLandingPage = {
      brand: brandInfo.brand,
      pageType: _urlParams.pageType,
      product: _urlParams.product,
      pageId: _urlParams.pageId,
      pageurl: pageurl,
      parenturl: _urlParams.parenturl ? _urlParams.parenturl : "",
      lang: sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language,
      isElectric: isElectric ? "electric" : "",
    };
    this.store.dispatch(PrismicActions.loadPrismic2({ prismicLandingPage: prismicLandingPage }));
    console.log("loadPageFromUrlParams: ", prismicLandingPage);
    this.store.dispatch(loadPrismic(prismicLandingPage));
  }

  getRatesFromStore() {
    this.ratesSub = this.store
      .select((store) => store.rates)
      .subscribe((rates) => {
        let _urlParams: URLParameters = JSON.parse(sessionStorage.getItem("urlParams"));
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));

        console.log("isRatingPage: ", this.pageService.isRatingPage(this.router.url));

        if (this.pageService.isRatingPage(this.router.url)) {
          if (!rates) {
            console.error("Could not find rates while going to rates page.");
            let _opportunityId: Opportunity = JSON.parse(sessionStorage.getItem("opp"));
            let landingPageUrl = this.pageService.getLandingPage();

            if (_opportunityId) {
              this.redirectTo(`/${landingPageUrl}`, _opportunityId.opportunityId);
              this.store.dispatch(account());
              this.loadPageFromUrlParams(landingPageUrl, _urlParams, _vehicle.isElectric);
            } else {
              this.redirectTo(`/${landingPageUrl}`, null);
              this.store.dispatch(guest());
              console.log('Ray here 7')
              this.loadPageFromUrlParams(landingPageUrl, _urlParams, _vehicle.isElectric);
            }
          } else {
            console.log("Rates Found From Subscription:", rates, _urlParams);
            this.loadPageFromUrlParams(this.pageService.getRatingPage(), _urlParams, _vehicle.isElectric);
          }
        }
        // else {
        //     console.log("Entering into Non-Rating Page", _urlParams);
        //     this.loadPageFromUrlParams(_urlParams.pageurl, _urlParams, _vehicle? _vehicle.isElectric: false);
        // }
      });
  }

  ngOnDestroy() {
    if (this.prismicSub) {
      this.prismicSub.unsubscribe();
    }

    if (this.ratesSub) {
      this.ratesSub.unsubscribe();
    }
  }

  createSlices(prismicContent) {
    // loop through Prismic content
    const slices = [];
    for (const slice of prismicContent) {
      if (slice.type === "content_block") {
        const contentBlockSlice = {
          type: slice.fields[0].content_block._meta.uid,
          body: slice.fields[0].content_block.body,
          text_color: slice.fields[0].content_block.text_color,
          background_color: slice.fields[0].content_block.background_color,
        };
        slices.push(contentBlockSlice);
      } else {
        slices.push(slice);
      }
    }
    return slices;
  }

  loadLeadPrid(params: URLParameters) {
    this.store.dispatch({
      type: "[Lead Component] Load Lead",
      payload: params,
    });
    this.store.dispatch(saveUrlParams(params));
  }

  loadLeadOpportunity(params: URLParameters) {
    this.store.dispatch({
      type: "[Lead Component] Load Lead Opportunity",
      payload: params,
    });
    this.store.dispatch(saveUrlParams(params));
  }

  ratesError() {
    // this.openModal(null);
    this.store.dispatch(ratesError());
    this.store.dispatch(hideInlineLoader());
  }

  loadRatesPage(urlParamsObject) {
    let _urlParams = JSON.parse(sessionStorage.getItem("urlParams"));
    urlParamsObject = _urlParams;
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));

    // if (_urlParams) {
    //   this.loadPageFromUrlParams(this.pageService.getRatingPage(), urlParamsObject, _vehicle.isElectric)
    // }
  }

  loadLandingPage(urlParamsObject) {
    this.leadSub = this.store
      .select((store) => store.lead)
      .subscribe((lead) => {
        if (!lead) {
          let _urlParams = JSON.parse(sessionStorage.getItem("urlParams"));

          if (urlParamsObject.pageurl !== undefined && _urlParams) {
            _urlParams.pageurl = urlParamsObject.pageurl;
          }

          if (urlParamsObject.oid) {
            //Check if oid exist
            console.log("Loading lead oid from url:", urlParamsObject);
            this.store.dispatch(account());
            this.loadLeadOpportunity(urlParamsObject);
          } else if (urlParamsObject.prid) {
            //Check if prid exist
            this.store.dispatch(account());
            console.log("Loading lead prid from url:", urlParamsObject);
            this.loadLeadPrid(urlParamsObject);
          } else if (_urlParams && _urlParams.prid) {
            //Check if url Params sessionstorage data exist
            console.log("Loading _urlParams from sessionStorage:", _urlParams);
            this.store.dispatch(account());
            this.loadLeadPrid(_urlParams);
          } else if (_urlParams && _urlParams.oid) {
            //Check sessionstorage for oid
            this.store.dispatch(account());
            this.loadLeadOpportunity(_urlParams);
          } else {
            // Guest Flow
            console.log("Dispatching guest flow", urlParamsObject);
            if (sessionStorage.getItem('appFlowState') !== 'serviceDrive') {
              this.store.dispatch(guest());
              console.log('Ray here 6')
            }
            this.store.dispatch(saveUrlParams(urlParamsObject));
            this.loadPageFromUrlParams(urlParamsObject.pageurl, urlParamsObject, false);
          }
        } else {
          // this.loadPageFromUrlParams(this.pageService.getRatingPage(), urlParamsObject, _vehicle.isElectric)
        }
      });

    let _lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    if (_lead) {
      let _opportunityId: Opportunity = JSON.parse(sessionStorage.getItem("opp"));
      if (_opportunityId && urlParamsObject.oid !== "" && _opportunityId && urlParamsObject.oid !== undefined && _opportunityId && urlParamsObject.oid !== null) {
        if (_opportunityId.opportunityId !== urlParamsObject.oid) {
          this.store.dispatch(resetRates());
          let _urlParams = JSON.parse(sessionStorage.getItem("urlParams"));

          if (urlParamsObject.pageurl !== undefined && _urlParams) {
            _urlParams.pageurl = urlParamsObject.pageurl;
          }

          if (urlParamsObject.oid !== undefined) {
            //Check if oid exist
            console.log("Loading lead oid from url:", urlParamsObject);
            this.store.dispatch(account());
            this.loadLeadOpportunity(urlParamsObject);
          } else if (urlParamsObject.prid !== undefined) {
            //Check if prid exist
            this.store.dispatch(account());
            console.log("Loading lead prid from url:", urlParamsObject);
            this.loadLeadPrid(urlParamsObject);
          } else if (_urlParams) {
            //Check if url Params sessionstorage data exist
            console.log("Loading lead from sessionStorage:", _urlParams);
            if (_urlParams.prid) {
              //Check sessionstorage for prid
              this.store.dispatch(account());
              this.loadLeadPrid(_urlParams);
            } else if (_urlParams.oid) {
              //Check sessionstorage for oid
              this.store.dispatch(account());
              this.loadLeadOpportunity(_urlParams);
            } else {
              this.loadGuestFlow(_urlParams);
            }
          } else {
            // Guest Flow
            console.log("Dispatching guest flow");
            this.loadGuestFlow(urlParamsObject);
          }
        } else {
          this.store.dispatch(account());
          let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
          this.loadPageFromUrlParams(urlParamsObject.pageurl, urlParamsObject, _vehicle.isElectric);
        }
      } else if (_opportunityId) {
        this.store.dispatch(account());
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
        this.loadPageFromUrlParams(urlParamsObject.pageurl, urlParamsObject, _vehicle.isElectric);
      } else {
        console.log("Dispatching guest flow if lead exists");
        this.loadGuestFlow(urlParamsObject);
      }
    }
  }

  loadGuestFlow(urlParamsObject) {
    console.log('Ray here 5')
    this.store.dispatch(guest());
    this.loadPageFromUrlParams(urlParamsObject.pageurl, urlParamsObject, "non-electric");
  }

  redirectTo(uri: string, queryParams) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";

    if (queryParams) {
      this.router.navigate([uri], { queryParams: { oid: queryParams } });
    } else {
      this.router.navigate([uri]);
    }
  }
}
