import { Customer } from "./customer";
import { ContractInformation } from './contract'
import { PayHistory } from './payment'
import { PaymentInformation } from './payment'
import { MyOrder } from './order';

export class AccountID {
  paylinkAccountId?: string;
  d2cAccountId?: string;
  paymentService?: any;
}

export class Opportunity {
    opportunityId: string;
}

export class Account {
    customer: Customer;
    contractInformation: ContractInformation;
    validUntil: string;
    data?: [
        orderId: any,
        customer: string
    ];
}

export class Paylink {
    paymentInformation: PaymentInformation;
    payHistory: PayHistory[];
}

export class PaylinkResponse {
    status: string;
    data: GetDataForResult;
    errorMessage: string;
}

export class GetDataForResult {
    getDataForResult: PaylinkData;
}

export class PaylinkData {
    status: string;
    shortMessage: string;
    longMessage: string;
    recordCount: number;
    serviceMessage: string;
    token: string;
    masterRecords: PaylinkMasterRecords;
}

export class PaylinkMasterRecords {
    clientDataRecord: PaylinkClientDataRecord[]
}

export class PaylinkClientDataRecord {
    accountNumber: string;
    accountStatus: string;
    policyNumber: string;
    customer: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    sellerCode: string;
    sellerName: string;
    adminCode: string;
    adminName: string;
    coverage: string;
    vehicleYear: number;
    vehicleMake: string;
    vehicleModel: string;
    vin: string;
    amountFinanced: number;
    totalPremium: number;
    downPayment: number;
    ledgerBalance: number;
    installmentAmount: number;
    dealerCost: number;
    totalPaidInPayments: number;
    totalPastDue: number;
    lateFeeDue: number;
    returnedAdminCost: number;
    returnedCommission: number;
    billingMethod: string;
    totalInstallments: number;
    paymentDueDay: number;
    paymentsMade: number;
    paymentsRemaining: number;
    coverageMileage: number;
    coverageTerm: number;
    createdDate: string;
    effectiveDate: string;
    firstPaymentDate: string;
    nextDueDate: string;
    nonPayCancelDate: string;
    pastDueDate: string;
    lastPaymentDate: string;
    companyCancelDate: string;
    nsffeeDue: number
}

export class AccountResponse {
    status: string;
    data: MyOrder[]
    errorMessage: string;
}

export class MyAccount {
    validUntil: string;
    coveragePlan: string;
    termLength: string;
    addons: string;
    make: string;
    model: string;
    ccLastFour: string;
    ccExpMonth: string;
    ccExpYear: string;
}

export class UpdatePayment {
    accountNumber: string;
    cc: string;
    ccMonth: string;
    ccName: string;
    ccYear: string;
    paymentService?: string;
    cvcValue: string;
}

export class UpdateBilling {
    accountNumber: string;
    address1: string;
    address2: string;
    cellPhone: string;
    city: string;
    email: string;
    insured1: string;
    insured2: string;
    phone: string;
    prefComMethod: string;
    state: string;
    workpPhone: string;
    zipCode: string;
    paymentService?: string

}

export class UpdateBillingInformation {
    payment: UpdatePayment;
    billing: UpdateBilling;
}

export class PaymentHistoryObject {
    paymentHistory: PaymentHistory[];
}

export class PaymentHistory {
    year: string;
    months: PaymentHistoryMonths[];
}

export class PaymentHistoryMonths {
    status: string;
    finalPayment: boolean;
}
export class PaymentHistoryYears {
    year: string;
}

export class AccountEmail {
    email: string;
}
