import * as ActionConfigurationManager from './config.actions'

export const loadAccount = ActionConfigurationManager.LOAD_ACCOUNT;
export const accountLoaded = ActionConfigurationManager.ACCOUNT_LOADED;
export const loadPaylink = ActionConfigurationManager.LOAD_PAYLINK;
export const paylinkLoaded = ActionConfigurationManager.PAYLINK_LOADED;
export const accountUpdated = ActionConfigurationManager.ACCOUNT_UPDATED;
export const updateBillingStateOn = ActionConfigurationManager.BILLING_UPDATE_STATE_ON
export const updateBillingStatOff = ActionConfigurationManager.BILLING_UPDATE_STATE_OFF
export const updatePaymentStateOn = ActionConfigurationManager.PAYMENT_UPDATE_STATE_ON
export const updateContactStateOn = ActionConfigurationManager.CONTACT_UPDATE_STATE_ON
export const updateBillingInformationStateOn = ActionConfigurationManager.BILLING_INFORMATION_UPDATE_STATE
export const noAccountFound = ActionConfigurationManager.NO_ACOOUNT_FOUND
export const updateBillingInformation = ActionConfigurationManager.UPDATE_BILLING_ADDRESS
export const updatePaymentInformation = ActionConfigurationManager.UPDATE_PAYMENT_INFORMATION
export const billingAddressUpdated = ActionConfigurationManager.BILLING_ADDRESS_UPDATED
export const paymentInformationUpdated = ActionConfigurationManager.PAYMENT_INFORMATION_UPDATED
export const loadPaymentHistory = ActionConfigurationManager.LOAD_PAYMENT_HISTORY
export const rehydratePaylink = ActionConfigurationManager.REHYDRATE_PAYLINK
export const rehydratePaymentHistory = ActionConfigurationManager.REHYDRATE_PAYMENT_HISTORY
export const rehydrateMyAccount = ActionConfigurationManager.REHYDRATE_MYACCOUNT
export const resetMyAccount = ActionConfigurationManager.RESET_MYACCOUNT
export const getAccountSFID = ActionConfigurationManager.GET_ACCOUNT_SFID
export const getAccountSFIDSuccess = ActionConfigurationManager.GET_ACCOUNT_SFID_SUCCESS
export const getAccountSFIDFailed = ActionConfigurationManager.GET_ACCOUNT_SFID_FAILED
export const loadSPPHistory = ActionConfigurationManager.LOAD_SPP_HISTORY;
export const loadSPPHistorySuccess = ActionConfigurationManager.LOAD_SPP_HISTORY_SUCCESS;
export const loadSPPHistoryFailure = ActionConfigurationManager.LOAD_SPP_HISTORY_FAILURE;
export const loadSppHistory = ActionConfigurationManager.LOAD_SPP_HISTORY;



